import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import "../assets/css/Home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import choco from "../assets/Images/chocotops.png";
import swal from "sweetalert";
import Loader from "./Loader";
import OtpForm from "./OtpForm";
import secureLocalStorage from "react-secure-storage";
import leaves from "../assets/Images/leaves.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo from "../assets/Images/Fresh Strawberry copy.jpg"
import logo2 from "../assets/Images/Nachos Jalapenos.jpg"
import logo3 from "../assets/Images/stadium pizza.jpeg"
import Feedback_video from "../assets/Images/Dessertino Investor Feedback.mp4"

const Index = () => {

  const slider = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    AOS.init({
      offset: 100, // offset (in px) from the original trigger point
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease-out",
      once: true,
    });
  }, []);

  // const navigate = useNavigate()
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [location, setLocation] = useState("");
  const [otherLocation, setOtherLocation] = useState("");
  // const [subject, setSubject] = useState("");
  const [states, setStates] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [state_loading, setStateLoading] = useState(false);

  const [otpBox, setOtpBox] = useState(false);
  // state for errors messages
  const [errors, setErrors] = useState({});

  useEffect(() => {
    AOS.init({
      offset: 100, // offset (in px) from the original trigger point
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease-out",
      once: false,
    });
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setErrors({})
    }
  }, [name, email, phone, message])

  useEffect(() => {
    function getStates() {
      setStateLoading(true)
      const formData = new FormData()
      formData.append("country_id", 1)

      axios.post(`https://franchise.dessertinoglobal.com/backend/public/api/user/country_state`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ovW56oOjEsp2TlJwQPSnoHbnwLfpf79lB10iN9S03sf0gMAQ5VOLxNjvS1dE`,
          "Content-Type": "multipart/form-data",
        }
      })
        .then((response) => {
          setStates(response.data.data)
        })
        .catch((err) => console.log(err))
        .finally(() => setStateLoading(false))
    }
    getStates()
  }, [])

  const [accordianAll, setAccordianAll] = useState(false)

  var userId;

  const validateForm = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = 'Name is required';
    }

    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = 'Invalid email format';
    }

    if (!phone) {
      newErrors.phone = 'Phone number is required';
    } else if (phone.length > 10 || phone.length < 10) {
      newErrors.phone = 'Phone number must be of 10 digits';
    }

    if (!location) {
      newErrors.location = 'Location is required';
    }

    if (!otherLocation && location == '0') {
      newErrors.otherLocation = 'Location is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onInquiry = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("location", location);
      formData.append("message", message);
      formData.append("other", otherLocation);

      try {
        const response = await axios({
          method: "post",
          url: "https://franchise.dessertinoglobal.com/backend/public/api/user/create",
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.message === "email already exists") {
          setErrors({ email: 'This Email is already taken!' });
          setLoading(false)
          return;
        }

        if (response.data.message === "User created successfully") {
          userId = response.data.user.id;
          secureLocalStorage.setItem('dummy', response.data.otp)
          secureLocalStorage.setItem('id', response.data.user.id)
          setOtpBox(true)
        }
        setLoading(false)
      } catch (error) {
        console.log(error)
        if (error.response?.data?.message == 'The email has already been taken.') {
          setErrors({ email: 'The email has already been taken.' });
        }

        setLoading(false);
      }
    }

  };

  const data = [
    {
      title: "How much does a franchise outlet cost?",
      desc: 'There are three models available for franchising: "The Desserts" model (approx. 300 Sq Ft) costs 30 to 32 Lacs, the "Cafe" Model (approx. 600 Sq Ft) costs 38 to 40  Lacs, and the "Restro" Model (approx. 1000 Sq Ft) costs 62 to 64 Lacs. The approval of the model type will be at the discretion of the brand.'
    },
    {
      title: "Do all models offer the same menu?",
      desc: 'The "Desserts" model offers a range of Ice Cream, Thick shakes, Milk Shakes, and waffles; the Café Model includes these products along with a range of hot beverages and a light eatery section. Additional items may be added based on regional demand. The "Restro" Model offers the entire Dessert Menu along with Eateries, Pizzas, and Italian Starters.'
    },
    {
      title: "Can I own more than one franchise?",
      desc: "Yes, you can, but only with a proven track record of successful unit operations. "
    },
    {
      title: "Do I need my own funds to be your franchisee?",
      desc: "No, it is not necessary. You may borrow from Banks, and the Company provides all the necessary quotations and supportive documentation to build your case. If you avail the funding, the interest becomes an allowable expense in the P&L. "
    },
    {
      title: "Do I have to pay the full investment amount directly to the brand?",
      desc: "No, the Company only collects the franchisee Fees. The remaining payment is made by you to the respective vendors and agencies, and you will completely monitor and guide this process."
    },
    {
      title: "Will the brand help me find a good location?",
      desc: "Yes, indeed. The Brand has a tie-up with PAN India property finders who are among the best in the Industry. They will provide their expertise to help finalize a suitable location."
    },
    {
      title: "Can anyone be your franchisee? What is the eligibility criteria for becoming your franchisee?",
      desc: "The Franchisee must be prepared to align with the brand's values and ethos. There is a franchisee application form that needs to be submitted to the head office for approval."
    },
    {
      title: "How much time will I have to devote personally to operate the dessertino outlet business?",
      desc: "Franchisees need to spend an average of 2 to 3 hours a day monitoring business operations and progress as per targets, while routine management is handled by the team following Standard Operating Procedures. "
    },
    {
      title: "How many people are required to run the operations of the outlet?",
      desc: "Approximately 4 to 8 people, depending on the model. "
    },
    {
      title: "Does the brand help with recruitment and training?",
      desc: "Yes. The Brand has tie-ups with recruitment consultants and agencies for manpower. Franchisees also need to make efforts to find local staff. Training is provided at the brand's training centre for both franchise owners and staff. "
    },
    {
      title: "What is the next step after agreeing to become a franchise?",
      desc: "The first step is to fill up the franchisee application form. Once approved, a Letter of Intent (LOI) needs to be signed, committing both parties. This attracts a non-refundable but adjustable payment of Rs 1,00,000. "
    },
    {
      title: "How long does it take to start the outlet after LOI?",
      desc: "It takes around 35 to 40 days to execute the store once the location is finalized. "
    },
    {
      title: "Does the brand extend support in Marketing?",
      desc: "Yes. The brand has a robust marketing plan for pre and post-launch, including digital media, launch offers, in-house design team for creatives, and periodic marketing calendars. "
    },
    {
      title: "How can franchisees improve their income and ROI?",
      desc: "Franchisees are exposed to various revenue opportunities like Dine In, Take Away, Delivery, and Online sales. Methods to improve revenue and ROI are shared during franchisee training. "
    },
    {
      title: "Does the brand provide for online platform registration?",
      desc: "Yes. The brand carries out the entire onboarding process with online platforms and supports all required documentation."
    },
    {
      title: "Is the menu pricing the same for all locations and across all platforms?",
      desc: "Pricing is the same state-wise, but online platform prices may vary depending on the region. "
    },
    {
      title: "Does the brand negotiate the lease on behalf of a franchisee?",
      desc: "Yes, the brand supports negotiations to secure the best lease deals based on set guidelines. "
    },
    {
      title: "What is the royalty percentage?",
      desc: "The royalty is 7% of sales."
    }
  ]

  return (
    <div>
      {isLoading && <Loader />}
      <Navbar />

      <a href="#knowmore" className="button fixed-left-btn py-2">Know More</a>


      {/* Banner section */}
      <section
        className="page-header py-5"
        style={{
          backgroundImage: "",
          backgroundPosition: "center",
        }}
      >
        <div className="container-box py-4">
          <div className="text-white">
            <img
              src={leaves}
              style={{ transform: "translateY(105px)" }}
              width={200}
              alt=""
            />
            <h1
              data-aos="fade-left"
              data-aos-delay="100"
              className="fw-bold display-5"
            >
              Join the <span style={{ color: "#99d017" }}>Dessert</span>  Revolution
            </h1>
            <p className="paragraph mt-5 fs-5 text-light text-uppercase">
              Become a franchise partner to Join the fastest growing dessertino brand
            </p>
          </div>
        </div>
      </section>

      <section className="py-5" id="register">
        <div className="container-box mb-md-5">
          <div className="row flex-md-row flex-column justify-content-evenly align-items-center">
            <div className="col-sm-3 bg-choco animated-image pt-5 d-lg-block d-none">
              <img src={choco} alt="" className="choco-img" />
            </div>
            <div className="col-md-6 ">
              <form
                onSubmit={onInquiry}
                className="form-box w-100"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="row">
                  <h2 className="mb-4" style={{ color: "#45485f" }}>
                    Fill Below Details
                  </h2>
                  <div className="col-12 col-xxl-6 mb-4">
                    {/* <label htmlFor="">Full Name</label> */}
                    <input
                      type="text"
                      placeholder="Full Name *"
                      className="form-field"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {errors.name && <span className='text-danger'>{errors.name}</span>}

                  </div>

                  <div className="col-12 col-xxl-6 mb-4">
                    {/* <label htmlFor="">Email Address</label> */}
                    <input
                      type="text"
                      placeholder="Email Address *"
                      className="form-field"
                      value={email}
                      onChange={(e) => setEmail(e.target.value.trim())}
                    />
                    {errors.email && <span className='text-danger'>{errors.email}</span>}

                  </div>

                  <div className="col-md-6 mb-4">
                    {/* <label htmlFor="">Phone Number</label> */}
                    <input
                      type="number"
                      placeholder="Phone Number *"
                      className="form-field"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    {errors.phone && <span className='text-danger'>{errors.phone}</span>}

                  </div>

                  <div className="col-md-6 mb-4">

                    {/* <label htmlFor="">Which Location?</label> */}
                    <select value={location} style={{fontSize: (state_loading ? '15px' : '16px')}} onChange={(e) => setLocation(e.target.value)}
                      className="form-field border-radius" >
                      <option value="" > 
                      {state_loading ? 'Location are Loading...' : 'Select Location'}  </option>
                      {
                        states.map((state) => {
                          return (
                            <option key={state.id}
                              name={state.name}
                              value={state.id}>
                              {state.name} </option>
                          )
                        })
                      }
                      {
                        !state_loading && <option value="0">Other</option>
                      }

                    </select>
                    {errors.location && <span className='text-danger'>{errors.location}</span>}


                  </div>

                  {
                    location == '0' &&
                    <div className="col-12 mb-4">
                      <input
                        type="text"
                        placeholder="Which Other Location? *"
                        className="form-field"
                        value={otherLocation}
                        onChange={(e) => setOtherLocation(e.target.value)}
                      />
                      {errors.otherLocation && <span className='text-danger'>{errors.otherLocation}</span>}
                    </div>
                  }


                  <div className="col-12">
                    {/* <label htmlFor=""> Your Address</label> */}
                    <textarea
                      rows={3}
                      placeholder="Your message (optional)"
                      className="form-field"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    {errors.message && <span className='text-danger'>{errors.message}</span>}
                  </div>

                  <div className="mt-4">
                    <button className="button">Save Details</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className={`pt-5 bg-cta ${accordianAll && 'bg-color'}`} id="knowmore">
        <div className="container-box text-md-start text-cpy-md-5 mt-5">
          <h2 className="fw-bold display-5 ps-3 ps-md-0 text-white">
            Interested to <span style={{ color: "#99d017" }}>Know More</span>{" "}
            About Us?{" "}
          </h2>

          <div className="mt-4 pb-2">
            {/* accordian */}
            <div className="accordion accordion-flush accordian-transparent" id={`accordionFlushExample`}>
              {
                data.slice(0, accordianAll ? 18 : 3).map((item, i) => {
                  return (
                    <div key={i} className="accordion-item bg-transparent">
                      <h2 className="accordion-header">
                        <button className="accordion-button text-white collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseThree${i}`} aria-expanded="false" aria-controls={`flush-collapseThree${i}`}>
                          <div className='index rounded-circle bg-blue text-white me-3'>{i + 1}</div> {item.title}
                        </button>
                      </h2>
                      <div id={`flush-collapseThree${i}`} className={`accordion-collapse collapse ${i == 0 && 'show'}`} data-bs-parent={`#accordionFlushExample`}>
                        <div className="accordion-body">
                          <p className="paragraph text-light">
                            {item.desc}
                          </p>

                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>

          <div className="mt-5 text-end">
            <button
              className="button bg-btn2 mb-5"
              onClick={() => setAccordianAll(!accordianAll)}
            >
              Show {accordianAll ? 'Less' : 'More'}
              <i className={`bi bi-arrow-${accordianAll ? 'up' : 'down'} ms-2`}></i>
            </button>
          </div>
        </div>


      </section>

      <section className="py-5 onboard" id="onboard">
        <div className="container-box py-md-5">
          <div>
            <h2 className="fw-bold display-5 ps-2 ps-md-0 mb-4">Lets Get You Onboard</h2>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6 bg-logo pt-5 d-md-block d-none">
              <div>
                <img src={logo} alt="Image 1" style={{ objectFit: "cover" }} />
                <img src={logo2} alt="Image 2" style={{ objectFit: "cover" }} />
                <img src={logo3} alt="Image 3" style={{ objectFit: "cover" }} />
                {/* Add more images as needed */}
              </div>
            </div>
            <div className="col-md-5 wrapper me-5">
              <ol className="c-stepper">
                <li className="c-stepper__item" data-step="1">
                  <a href="#register" className="text-decoration-none">
                    <div className="c-stepper__content text-dark">
                      <h3 className="c-stepper__title">Application Submission</h3>
                      <p className="c-stepper__desc">Just fill a quick form</p>
                    </div>
                  </a>
                </li>
                <li className="c-stepper__item" data-step="2">
                  <a href="#register" className="text-decoration-none">
                    <div className="c-stepper__content text-dark">
                      <h3 className="c-stepper__title">Interview & Review</h3>
                      <p className="c-stepper__desc">Let's know each other</p>
                    </div>
                  </a>
                </li>
                <li className="c-stepper__item" data-step="3">
                  <a href="#register" className="text-decoration-none">
                    <div className="c-stepper__content text-dark">
                      <h3 className="c-stepper__title">Franchise Agreement</h3>
                      <p className="c-stepper__desc">Its onboard time</p>
                    </div>
                  </a>
                </li>
                <li className="c-stepper__item" data-step="4">
                  <a href="#register" className="text-decoration-none">
                    <div className="c-stepper__content text-dark">
                      <h3 className="c-stepper__title">Store Review & BuildOut</h3>
                      <p className="c-stepper__desc">Verify with guidelines</p>
                    </div>
                  </a>
                </li>
                <li className="c-stepper__item" data-step="5">
                  <a href="#register" className="text-decoration-none">
                    <div className="c-stepper__content text-dark">
                      <h3 className="c-stepper__title">Training</h3>
                      <p className="c-stepper__desc">Know in & out of our success</p>
                    </div>
                  </a>
                </li>
                <li className="c-stepper__item" data-step="6">
                  <a href="#register" className="text-decoration-none">
                    <div className="c-stepper__content text-dark">
                      <h3 className="c-stepper__title">Grand Opening</h3>
                      <p className="c-stepper__desc">Yes we are live</p>
                    </div>
                  </a>
                </li>
                <li className="c-stepper__item" data-step="7">
                  <a href="#register" className="text-decoration-none">
                    <div className="c-stepper__content text-dark">
                      <h3 className="c-stepper__title">Ongoing review and growth</h3>
                      <p className="c-stepper__desc">Hand holding you for peak performance</p>
                    </div>
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      {/* style={{ backgroundColor: "#d1cbcb" }} */}

      <section className="bg-white" id="feedback">
        <div data-aos="zoom-in" data-aos-duration="1500">
          <video className="w-100" controls >
            <source src={Feedback_video} type="video/mp4" />
          </video>
        </div>
      </section>

      <section className="py-5 feedback" style={{ backgroundColor: "#d1cbcb" }} >
        <div className="container-box py-md-5">
          <div>
            <h2 className="fw-bold display-5 text-white ps-3 ps-md-0 text-capitalize">Love From our customers</h2>
          </div>

          <div className="mt-5">
            <Slider {...slider}>
              <div className="col-md-5 px-4">
                <div
                  className="bg-white review-box p-4"
                  style={{ borderRadius: "1px 50px 1px 50px" }}
                >
                  <div className="mt-4 text-center" >
                    <i className="bi bi-quote"></i>
                    <p className="paragraph">
                      "Absolutely heavenly! Every bite of Sizzling Brownie takes me to dessert paradise. Thank you, Dessertino, for making my sweet dreams come true!"
                    </p>
                    {/* <a href="#"> Read Full Blog <i className="bi bi-chevron-double-right arrow-animation"></i> </a> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 px-4">
                <div
                  className="bg-white review-box p-4"
                  style={{ borderRadius: "1px 50px 1px 50px" }}
                >
                  <div className="mt-4 text-center" >
                    <i className="bi bi-quote"></i>
                    <p className="paragraph">
                      "I've never tasted anything quite like the Brewing Brownie thickshake from Dessertino It's a symphony of flavours that dance on my taste buds with every spoonful. Pure bliss!"
                    </p>
                    {/* <a href="#"> Read Full Blog <i className="bi bi-chevron-double-right arrow-animation"></i> </a> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 px-4">
                <div
                  className="bg-white review-box p-4"
                  style={{ borderRadius: "1px 50px 1px 50px" }}
                >
                  <div className="mt-4 text-center" >
                    <i className="bi bi-quote"></i>
                    <p className="paragraph">
                      "As a dessert connoisseur, I can confidently say that Dessertino sets the bar sky-high! Their Alfonso Mango thick shake is a masterpiece of culinary delight that never fails to impress."
                    </p>
                    {/* <a href="#"> Read Full Blog <i className="bi bi-chevron-double-right arrow-animation"></i> </a> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 px-4">
                <div
                  className="bg-white review-box p-4"
                  style={{ borderRadius: "1px 50px 1px 50px" }}
                >
                  <div className="mt-4 text-center" >
                    <i className="bi bi-quote"></i>
                    <p className="paragraph">
                      "Indulging in Rose petal ice cream from Dessertino is like taking a journey to dessert heaven. The perfect balance of flavours and textures makes it an unforgettable treat!"
                    </p>
                    {/* <a href="#"> Read Full Blog <i className="bi bi-chevron-double-right arrow-animation"></i> </a> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 px-4">
                <div
                  className="bg-white review-box p-4"
                  style={{ borderRadius: "1px 50px 1px 50px" }}
                >
                  <div className="mt-4 text-center" >
                    <i className="bi bi-quote"></i>
                    <p className="paragraph">
                      "Thank you, Dessertino, for creating such divine desserts! Your Figgy cashew thick shake has become my go-to indulgence whenever I need a little sweetness in my day."
                    </p>
                    {/* <a href="#"> Read Full Blog <i className="bi bi-chevron-double-right arrow-animation"></i> </a> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 px-4">
                <div
                  className="bg-white review-box p-4"
                  style={{ borderRadius: "1px 50px 1px 50px" }}
                >
                  <div className="mt-4 text-center" >
                    <i className="bi bi-quote"></i>
                    <p className="paragraph">
                      "I can't get enough of Dessertino's Italian Mummy It's like a little bundle of happiness in every bite. Truly the best Italian starter I've ever had!"
                    </p>
                    {/* <a href="#"> Read Full Blog <i className="bi bi-chevron-double-right arrow-animation"></i> </a> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 px-4">
                <div
                  className="bg-white review-box p-4"
                  style={{ borderRadius: "1px 50px 1px 50px" }}
                >
                  <div className="mt-4 text-center" >
                    <i className="bi bi-quote"></i>
                    <p className="paragraph">
                      "I've been a loyal customer of Dessertino for years, and their desserts never disappoint. From their decadent Waffles to their irresistible Hot shakes, everything is made with love and it shows!"
                    </p>
                    {/* <a href="#"> Read Full Blog <i className="bi bi-chevron-double-right arrow-animation"></i> </a> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 px-4">
                <div
                  className="bg-white review-box p-4"
                  style={{ borderRadius: "1px 50px 1px 50px" }}
                >
                  <div className="mt-4 text-center" >
                    <i className="bi bi-quote"></i>
                    <p className="paragraph">
                      "Whenever I'm craving something sweet, I know I can count on Dessertino to deliver the perfect dessert. Their Choco-hazelnut pop is a game-changer!"
                    </p>
                    {/* <a href="#"> Read Full Blog <i className="bi bi-chevron-double-right arrow-animation"></i> </a> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 px-4">
                <div
                  className="bg-white review-box p-4"
                  style={{ borderRadius: "1px 50px 1px 50px" }}
                >
                  <div className="mt-4 text-center" >
                    <i className="bi bi-quote"></i>
                    <p className="paragraph">
                      "Bravo, Dessertino! Your Volcanic paneer Pizza is a work of art that deserves all the praise. It's like little slices of heaven on a plate."
                    </p>
                    {/* <a href="#"> Read Full Blog <i className="bi bi-chevron-double-right arrow-animation"></i> </a> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 px-4">
                <div
                  className="bg-white review-box p-4"
                  style={{ borderRadius: "1px 50px 1px 50px" }}
                >
                  <div className="mt-4 text-center" >
                    <i className="bi bi-quote"></i>
                    <p className="paragraph">
                      "I'm officially obsessed with Dessertino’ s entire Menu! Each one is a delightful masterpiece that leaves me craving more. Thank you for making dessert time the best time!"
                    </p>
                    {/* <a href="#"> Read Full Blog <i className="bi bi-chevron-double-right arrow-animation"></i> </a> */}
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>


      <footer >
        <div className="container-box">
          <div className="d-md-flex justify-content-between align-items-cpy-4 text-white text-md-start text-center">
            <div className=" text-center">
              <span>
                {" "}
                Copyrights © {new Date().getFullYear()} Dessertino. All Rights
                Reserved.
              </span>
            </div>
            <div className=" text-md-end mt-md-0 mt-2">
              <span>
                Designed by{" "}
                <a
                  href="https://www.zithas.com"
                  target="_blank"
                  className="text-decoration-none fw-bold text-white"
                >
                  Zithas Technologies
                </a>
              </span>
            </div>
          </div>
        </div>
      </footer>


      {
        otpBox &&
        <OtpForm
          otpBox={otpBox}
          form_states={{
            setName,
            setEmail,
            setPhone,
            setMessage,
            setLocation,
            setOtherLocation
          }}
          setOtpBox={setOtpBox} data={{
            name,
            phone,
            email,
            message,
            location,
            userId
          }} />
      }
    </div>
  );
};

export default Index;
