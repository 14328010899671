import axios from 'axios';
import React, { useState } from 'react'
import secureLocalStorage from 'react-secure-storage';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import Loader from './Loader';

// https://franchise.dessertinoglobal.com/backend/public/api/usser
// https://ztpl.net/dessertino/admin/api/usser
// https://192.168.0.2/unify/dessertino/admin/backend/public/api/usser

const OtpForm = ({ otpBox, setOtpBox, data, form_states }) => {
    const [otp, setOtp] = useState('');
    const [isLoading, setLoading] = useState(false);

    const handleVerify = async () => {
        try {
            const otpString = otp.join('');
            
            if (secureLocalStorage.getItem("dummy") === otpString) {
                var id = secureLocalStorage.getItem("id");
                setLoading(true);
                const formData = new FormData();
                formData.append("user_id", id);
                const response = await axios.post("https://franchise.dessertinoglobal.com/backend/public/api/user/otp_verify", formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    }
                });

                setOtpBox(false);

                swal("Inquiry Sent!", "Kindly Check Your Mail", "success")
                    .then(() => {
                        window.scrollTo(0, 0)
                        form_states.setName('')
                        form_states.setEmail('')
                        form_states.setPhone('')
                        form_states.setMessage('')
                        form_states.setLocation('')
                        form_states.setOtherLocation('')
                    });

                setLoading(false);
            } else {
                swal({
                    icon: "error",
                    text: "Invalid Otp !",
                });
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            swal({
                icon: "error",
                text: "An error occurred while verifying OTP.",
            });
        }
    };


    const handleKeyPress = (e) => {
        // Get the value of the key pressed
        const keyValue = e.key;
        // Check if the key pressed is a numeric character or a control key (like backspace)
        if (!/\d/.test(keyValue) && keyValue !== "Backspace" && keyValue !== "Delete" && keyValue !== "ArrowLeft" && keyValue !== "ArrowRight" && keyValue !== "Tab") {
            e.preventDefault(); // Prevent the default action (typing the character)
        }
    };
    return (
        <div>
            {isLoading && <Loader />}
            <Modal show={otpBox} onHide={() => setOtpBox(false)} centered >
                <div style={{ margin: '40px', marginBottom: '0px', marginTop: '50px', textAlign: 'left', fontWeight: '800', color: 'white' }}>
                    <h3>OTP Verification</h3>
                </div>
                <Modal.Body style={{ padding: '40px' }}>
                    <p>We've sent a verification code to your email - <span className='fw-bold' >{data.email}</span></p>
                    <div className="otp-field my-4">
                        {[...Array(6)].map((_, index) => (
                            <input
                                key={index}
                                type="number"
                                value={otp[index] || ''}
                                onChange={(e) => {
                                    const enteredValue = e.target.value;
                                    if (!isNaN(enteredValue)) {
                                        const newOtp = [...otp];
                                        newOtp[index] = enteredValue[enteredValue.length - 1];
                                        setOtp(newOtp);
                                        if (index < 5 && enteredValue !== '') {
                                            const nextInput = document.getElementById(`otp-input-${index + 1}`);
                                            if (nextInput) nextInput.focus();
                                        }
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        // Prevent the default backspace action
                                        e.preventDefault();
                                        // Remove the current digit
                                        const newOtp = [...otp];
                                        newOtp[index] = '';
                                        setOtp(newOtp);
                                        // Move focus to the previous input field
                                        const prevInput = document.getElementById(`otp-input-${index - 1}`);
                                        if (prevInput) prevInput.focus();
                                    }
                                }}
                                onKeyPress={handleKeyPress}
                                className="otp-input mx-1"
                                min="0" max="9"
                                id={`otp-input-${index}`}
                            />
                        ))}
                    </div>
                    <div className="text-center me-4">
                        <button className="button btn-primary" style={{ backgroundColor: '#6c9fe6', width: '100%', marginTop: '20px' }} onClick={handleVerify} disabled={otp.length !== 6}>
                            Verify
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default OtpForm