import React, { useState } from "react";
import logo from "../assets/Images/Dessertino-Logonew.png";
import { Link } from "react-router-dom";

const Navbar = ({ className }) => {

  const [stickyNavbar, setStickyNavbar] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(false)

  window.addEventListener('scroll', () => {
    if (window.scrollY > 30) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }
  })

  return (
    <div>
      <nav className={`pd-navbar ${stickyNavbar ? 'skicky-navbar' : ''} ${className}`}>
        <div className="container-box">
          <div className="row w-100 justify-space-between align-items-center ">
            <div className="col-2 text-md-start text-center">
              <Link to="/" className="navbar-brand" >
                <img src={logo} alt="Logo" height="100" onClick={() => window.scrollTo(0, 0)}></img>
              </Link>
            </div>
            <div className="col-10 right-nav">

              <ul className={`d-md-flex align-items-center mb-0 ps-0 h-100 navlinks ${mobileMenu && 'd-block'}`}>
                <li><a href="#knowmore">Know More</a></li>
                <li><a href="#onboard">Onboard</a></li>
                <li><a href="#feedback">Feedback</a></li>
                <li><a href="#register">Register</a></li>
              </ul>

              <div className="menu-btn px-2 rounded-circle shadow" onClick={() => setMobileMenu(!mobileMenu)}>
                <i className={`bi bi-${mobileMenu ? 'x' : 'list'} list text-pink fs-3`}></i>
              </div>

              <div className="d-xl-flex justify-content-md-end ms-ms-0 ms-4 justify-content-start">
                <div className="mx-xl-3 ps-0 width-100">
                  <i className="bi bi-telephone text-white me-2"></i>
                  <a href="tel:099044 44457" className="text-light fw-bold text-decoration-none contact-text">99241 98933</a>
                </div>
                <div className="mx-xl-3 ps-0 width-100">
                  <i className="bi bi-envelope text-white me-2"></i>
                  <a href="mailto:info@dessertinoglobal.com"
                    className="text-light fw-bold text-decoration-none contact-text">franchise@dessertinoglobal.com</a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
